// -----------------------------------------------------------------------------------------------------
@use '@angular/material' as mat;
@use '@fuse/scss/partials/colors.scss' as *;
@use '@fuse/scss/theme.scss' as *;
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------
$fuse-white: (
    500: white,
    contrast: (
        500: rgba(black, 0.87)
    )
);

$fuse-black: (
    500: black,
    contrast: (
        500: white
    )
);

$fuse-navy: (
    50: #ececee,
    100: #c5c6cb,
    200: #9ea1a9,
    300: #7d818c,
    400: #5c616f,
    500: #3c4252,
    600: #353a48,
    // 700: #2D323E,
    700: #f9f9f9,
    800: #262933,
    // 900: #1E2129,
    900: #f9f9f9,
    A100: #c5c6cb,
    A200: #9ea1a9,
    A400: #5c616f,
    A700: #2d323e,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: rgba(black, 0.87),
        A200: white,
        A400: white,
        A700: white
    )
);

// Generate Fuse color classes for custom palettes
$custom_palettes: (
    fuse-white: $fuse-white,
    fuse-black: $fuse-black,
    fuse-navy: $fuse-navy
);

@include fuse-color-classes($custom_palettes);

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

// Angular Material typography
$typography: mat.define-typography-config(
    $font-family: 'Muli, Helvetica Neue, Arial, sans-serif',
    $body-2: mat.define-typography-level(14px, 24px, 600),
    $button: mat.define-typography-level(14px, 14px, 600),
);

// Setup the typography
@include mat.all-component-typographies($typography);

// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for them
// @use "src/app/layout/components/chat-panel/chat-panel.theme";
// @use "src/app/layout/components/toolbar/toolbar.theme";

// @use "src/app/main/apps/academy/academy.theme";
// @use "src/app/main/apps/calendar/calendar.theme";
// @use "src/app/main/apps/chat/chat.theme";
// @use "src/app/main/apps/contacts/contacts.theme";
// @use "src/app/main/apps/dashboards/project/project.theme";
// @use "src/app/main/apps/e-commerce/orders/orders.theme";
// @use "src/app/main/apps/e-commerce/products/products.theme";
// @use "src/app/main/apps/file-manager/file-manager.theme";
// @use "src/app/main/apps/mail/mail.theme";
// @use "src/app/main/apps/mail-ngrx/mail.theme";
// @use "src/app/main/apps/scrumboard/scrumboard.theme";
// @use "src/app/main/apps/todo/todo.theme";

// @use "src/app/main/pages/authentication/login/login.theme";
// @use "src/app/main/pages/authentication/login-2/login-2.theme";
// @use "src/app/main/pages/authentication/register/register.theme";
// @use "src/app/main/pages/authentication/register-2/register-2.theme";
// @use "src/app/main/pages/authentication/reset-password/reset-password.theme";
// @use "src/app/main/pages/authentication/reset-password-2/reset-password-2.theme";
// @use "src/app/main/pages/authentication/forgot-password/forgot-password.theme";
// @use "src/app/main/pages/authentication/forgot-password-2/forgot-password-2.theme";
// @use "src/app/main/pages/authentication/lock/lock.theme";
// @use "src/app/main/pages/authentication/mail-confirm/mail-confirm.theme";
// @use "src/app/main/pages/coming-soon/coming-soon.theme";
// @use "src/app/main/pages/errors/404/error-404.theme";
// @use "src/app/main/pages/errors/500/error-500.theme";
// @use "src/app/main/pages/maintenance/maintenance.theme";
// @use "src/app/main/pages/pricing/style-1/style-1.theme";
// @use "src/app/main/pages/pricing/style-2/style-2.theme";
// @use "src/app/main/pages/pricing/style-3/style-3.theme";
// @use "src/app/main/pages/profile/tabs/about/about.theme";
// @use "src/app/main/pages/profile/tabs/photos-videos/photos-videos.theme";
// @use "src/app/main/pages/profile/tabs/timeline/timeline.theme";
// @use "src/app/main/pages/search/classic/search-classic.theme";
// @use "src/app/main/pages/search/modern/search-modern.theme";
// @use "src/app/main/pages/faq/faq.theme";
// @use "src/app/main/pages/knowledge-base/knowledge-base.theme";

// @use "src/app/main/ui/cards/cards.theme";

// @use "src/app/main/angular-material-elements/example-viewer/example-viewer.theme";

// Define a mixin for easier access
@mixin components-theme($theme) {
    //     // Layout components
    //     @include chat-panel-theme($theme);
    //     @include toolbar-theme($theme);

    //     // Apps
    //     @include academy-theme($theme);
    //     @include calendar-theme($theme);
    //     @include chat-theme($theme);
    //     @include contacts-theme($theme);
    //     @include dashboards-project-theme($theme);
    //     @include e-commerce-orders-theme($theme);
    //     @include e-commerce-products-theme($theme);
    //     @include file-manager-theme($theme);
    //     @include mail-theme($theme);
    //     @include mail-ngrx-theme($theme);
    //     @include scrumboard-theme($theme);
    //     @include todo-theme($theme);

    //     // Pages
    //     @include login-theme($theme);
    //     @include login-2-theme($theme);
    //     @include register-theme($theme);
    //     @include register-2-theme($theme);
    //     @include reset-password-theme($theme);
    //     @include reset-password-2-theme($theme);
    //     @include forgot-password-theme($theme);
    //     @include forgot-password-2-theme($theme);
    //     @include lock-theme($theme);
    //     @include mail-confirm-theme($theme);
    //     @include coming-soon-theme($theme);
    //     @include error-404-theme($theme);
    //     @include error-500-theme($theme);
    //     @include maintenance-theme($theme);
    //     @include pricing-style-1-theme($theme);
    //     @include pricing-style-2-theme($theme);
    //     @include pricing-style-3-theme($theme);
    //     @include profile-about-theme($theme);
    //     @include profile-photos-videos-theme($theme);
    //     @include profile-timeline-theme($theme);
    //     @include search-classic-theme($theme);
    //     @include search-modern-theme($theme);
    //     @include faq-theme($theme);
    //     @include knowledge-base-theme($theme);

    //     // UI
    //     @include ui-cards-theme($theme);

    //     // Example Viewer
    //     @include example-viewer-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

// Define the default density
$default-density: 0;

// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette($fuse-navy);
$default-accent-palette: mat.define-palette(mat.$light-blue-palette, 600, 400, 700);
$default-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$theme: mat.define-light-theme((
    color: (primary: $default-primary-palette, accent: $default-accent-palette, warm: $default-warn-palette),
    typography: $typography,
    density: $default-density,
));

@include mat.all-component-themes($theme);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {
    // Create an Angular Material theme from the $theme map
    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);

    // Apply the theme to the user components
    @include components-theme($theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $default-primary-palette,
        accent: $default-accent-palette,
        warn: $default-warn-palette
    );

    @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a yellow light theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$yellow-light-theme-primary-palette: mat.define-palette($fuse-navy, 600, 400, 700);
$yellow-light-theme-accent-palette: mat.define-palette(mat.$yellow-palette, 600, 400, 700);
$yellow-light-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$yellow-light-theme: mat.define-light-theme((
    color: (
        primary: $yellow-light-theme-primary-palette,
        accent: $yellow-light-theme-accent-palette,
        warn: $yellow-light-theme-warn-palette,
    ),
    typography: $typography,
    density: $default-density,
));

// Add ".theme-yellow-light" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-yellow-light {
    // Generate the Angular Material theme
    @include mat.all-component-colors($yellow-light-theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($yellow-light-theme);

    // Apply the theme to the user components
    @include components-theme($yellow-light-theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $yellow-light-theme-primary-palette,
        accent: $yellow-light-theme-accent-palette,
        warn: $yellow-light-theme-warn-palette
    );

    @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a blue-gray dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$blue-gray-dark-theme-primary-palette: mat.define-palette(mat.$blue-palette);
$blue-gray-dark-theme-accent-palette: mat.define-palette(mat.$blue-gray-palette);
$blue-gray-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$blue-gray-dark-theme: mat.define-dark-theme((
    color: (
        primary: $blue-gray-dark-theme-primary-palette,
        accent: $blue-gray-dark-theme-accent-palette,
        warn: $blue-gray-dark-theme-warn-palette,
    ),
    typography: $typography,
    density: $default-density,
));

// Add ".theme-blue-gray-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-blue-gray-dark {
    // Generate the Angular Material theme
    @include mat.all-component-colors($blue-gray-dark-theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($blue-gray-dark-theme);

    // Apply the theme to the user components
    @include components-theme($blue-gray-dark-theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $blue-gray-dark-theme-primary-palette,
        accent: $blue-gray-dark-theme-accent-palette,
        warn: $blue-gray-dark-theme-warn-palette
    );

    @include fuse-color-classes($palettes);
}

// -----------------------------------------------------------------------------------------------------
// @ Define a pink dark theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$pink-dark-theme-primary-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-accent-palette: mat.define-palette(mat.$pink-palette);
$pink-dark-theme-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$pink-dark-theme: mat.define-dark-theme((
    color: (
        primary: $pink-dark-theme-primary-palette,
        accent: $pink-dark-theme-accent-palette,
        warn: $pink-dark-theme-warn-palette,
    ),
    typography: $typography,
    density: $default-density,
));

// Add ".theme-pink-dark" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-pink-dark {
    // Generate the Angular Material theme
    @include mat.all-component-colors($pink-dark-theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($pink-dark-theme);

    // Apply the theme to the user components
    @include components-theme($pink-dark-theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $pink-dark-theme-primary-palette,
        accent: $pink-dark-theme-accent-palette,
        warn: $pink-dark-theme-warn-palette
    );

    @include fuse-color-classes($palettes);
}

