// Import Fuse core library
@use '@fuse/scss/core';

// Import app.theme.scss
@use 'app/app.theme';

@use 'perfect-scrollbar/css/perfect-scrollbar.css';

// General page

.di-page {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 100%;
    min-width: 100%;

    &.di-edit,
    &.di-view {
        background: #fff;
    }
}

.di-bg {
    background: #fff;
}

.di-white-bg {
    background: white;
}

.di-padded-page {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    position: relative;
    z-index: 2;
    padding: 0 16px;
    width: 100%;
    min-width: 0;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
}

.di-shadow {
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    // -moz-box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
    // -webkit-box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
    // box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
}

.di-page-header {
    height: 45px;
    max-height: 45px;
    margin-top: 5px;

    &.di-edit,
    &.di-view {
        margin-top: 5px;
    }
}

.di-page-header-flexible {
    min-height: 45px;
    margin-top: 5px;
}

.ant-table-thead {
    background: #2cabb8;

    * {
        color: #fff !important;
    }

    > tr > th {
        background: #2cabb8 !important;
    }
}

.ant-table {
    color: #fff;
    font-weight: bold !important;
}

.di-chip {
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: inline-flex;
    padding: 7px 12px 7px 14px;
    border-radius: 24px;
    align-items: center;
    cursor: default;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}

.di-ordinal-bar {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: center space-between;
    align-items: center;
    border: 1px solid cadetblue;
    border-radius: 4px;
    margin-bottom: 16px;

    .di-ordinal {
        font-size: 2em;
        font-weight: bold;
        color: white;
        padding: 0 16px;
        background: cadetblue;
    }

    .di-ordinal-actions {
        padding: 0 16px;
    }

    .di-ordinal-action {
        cursor: pointer;
    }
}

.di-page-content {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    overflow: hidden;
    max-height: calc(100vh - 110px);
    // above calculated as: 100vh - (toolbar 64px + header 60px + lower padding 36px)

    &.di-auto-scroll {
        overflow: auto;
    }

    &.di-edit,
    &.di-view {
        background: white;
        margin-top: 6px;
        padding-top: 12px;
        // -moz-box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
        // -webkit-box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
        // box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
        overflow: auto;
    }

    // FIXME: check
    .di-view-pane,
    .di-edit-pane {
        max-height: calc(100vh - 170px);
    }

    .di-list-pane {
        max-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        position: relative;
        // box-shadow: 0 -1px 10px -4px rgba(115,115,115,0.75);

        .di-table-pane {
            // border-top: 1px solid gainsboro;
            // border-bottom: 1px solid gainsboro;
            border-radius: 4px;
            //box-shadow: 0 1px 10px -4px rgba(115, 115, 115, 0.75);
        }

        .di-pagination-pane {
            padding: 5px;
        }
    }
}

.di-shadow-bottom {
    box-shadow: 0 1px 10px -4px rgba(115, 115, 115, 0.75);
}

.di-status {
    padding: 1px 4px;
    border-radius: 2px;
    font-size: 0.9em;
}

.di-clickable {
    cursor: pointer;
}

.di-truncate {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ant-input-disabled,
.ant-input[disabled],
.ant-select-disabled .ant-select-selection,
.ant-time-picker-input[disabled] {
    color: rgba(0, 0, 0, 0.75) !important;
    background-color: #ffffff !important;
}

// .ant-select-disabled .ant-select-selection {
//     color: rgba(0,0,0,.75) !important;
//     background: #ffffff !important;
// }

// End of list page
.border {
    border: 1px solid black;
}

body * {
    font-family: Muli, 'Helvetica Neue', Arial, sans-serif;
    // color: rgba(0, 0, 0, 0.90);
}

.di-modal-title {
    background: aliceblue;
    height: 40px;

    h2 {
        margin: 0 12px;
    }
}

.di-modal-body {
    border-left: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
    border-right: 1px solid gainsboro;
    width: 100%;
    margin: 0;
    padding: 12px;
    // w-100-p m-0 p-12
}

.di-text {
    color: rgba(0, 0, 0, 0.85);
}

.di-row-action-button {
    margin-top: 22px;
}

.di-remove-button {
    color: #f41f1f !important;
}

// Add app wide styles here.

.ant-form-item {
    margin-bottom: 8px !important;
}

.flex-grow {
    flex: 1 1 0%;
}

.em75 {
    font-size: 0.75em;
}

.em8 {
    font-size: 0.8em;
}

.em9 {
    font-size: 0.9em;
}

.emh125 {
    font-size: 1.25em;
}

.emh2 {
    font-size: 2em;
}

// .inline-list-action-td {
//     height: 48px;
// }

.icon-disabled {
    color: #6c757d;
}

.default-cursor {
    cursor: not-allowed;
}

.font-bold {
    font-weight: bold;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.blink {
    animation: blinker 0.4s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
}

@keyframes blinker {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.modal-border {
    width: 100%;
    border: #f7f7ff solid 1px;
}

.show-if-error {
    display: none;
}

.di-error {
    color: #d8000c;

    .show-if-error {
        display: inline;
    }
}

// Adding global scss for HaulierMagic

.di-view-row {
    padding: 2px;
    margin-bottom: 8px;
}

.di-form-row,
.di-view-row {
    display: flex;
    flex-direction: row;

    di-input,
    di-grouped-input,
    di-search-select,
    di-time,
    di-textarea,
    di-slider-toggle,
    di-select,
    di-date,
    di-color,
    di-text-view,
    di-color-view,
    di-boolean-view,
    di-checkbox,
    di-date-time-view {
        padding-left: 4px;
        padding-right: 4px;
    }

    .ant-input {
        border-radius: 4px !important;
    }

    .ant-form-item-label {
        line-height: 2rem !important;
        margin: 0 0 0 !important;
    }

    .ant-form-item-control {
        line-height: 2rem !important;
    }
}

.no-margin {
    .ant-form-item {
        margin-bottom: 0 !important;
    }

    .ant-form-item-control {
        line-height: 2rem !important;
    }

    .ant-input {
        border-radius: 4px !important;
    }
}

.di-form-section,
.di-view-section {
    margin: 8px 0;
    display: flex;
    flex-direction: column;
}

.di-form-section-title,
.di-view-section-title {
    display: flex;
    flex-direction: row;
    // background-color: #f2f2f2;
    margin: 0px 8px 16px;
    padding-left: 8px;
    justify-content: space-between;
    align-items: center;
    color: cadetblue;
    font-size: 1.5em;
    border-bottom: 2px solid cadetblue;
    // height: 40px;
}

.di-form-section-container,
.di-view-section-container {
    font-weight: inherit;
}

.di-form-section-container-boarder,
.di-view-section-container-boarder {
    font-weight: inherit;
    padding: 8px;
    border-style: solid;
}

.half-width {
    width: 50%;
}

.di-indented {
    margin-left: 64px;
}

.flex-container {
    display: flex;
}

.flex-dir-col {
    flex-direction: column;
}

.fill-xy {
    width: 100%;
    height: 100%;
}

.remove-item-button {
    color: #dc2518c4;
}

.di-modal-title {
    background-color: aliceblue;
}

.validation-error {
    color: #f44336;
    font-size: 0.8em;
}

// di-color component
.mcc-color-picker-selector-preview {
    min-height: 70px !important;
}

mcc-color-picker {
    button {
        position: absolute;
    }
}

.color-circle {
    width: 30px;
    border-radius: 15px;
    height: 30px;
}

// Status colors

.status-fail,
.status-pack-pending {
    background-color: rgb(245, 88, 88);
    color: white;
}

.status-warn,
.status-inventory-pending {
    background-color: orange;
    color: white;
}

.status-pass {
    background-color: #2cabb8;
    color: white;
}

.status-draft,
.status-lo-processed {
    background-color: #f0f33b;
    color: black;
}

.status-approved,
.status-pack-packed,
.status-inv-invoiced,
.status-inventory-received {
    background-color: #2cabb8;
    color: white;
}

.status-pending,
.status-pick-pending {
    background-color: #cdd6d5 !important;
}

.status-confirmed {
    background-color: #f0b99a !important;
}

.status-picked,
.status-inv-proforma {
    background-color: #ecd2a2 !important;
}

.status-packed,
.status-inv-ready {
    background-color: #e6ee9c !important;
}

.status-inv-consolidated {
    background-color: #a7a7a7 !important;
}

.status-in-progress,
.status-pick-in-progress,
.status-pack-in-progress {
    background-color: #f4b504 !important;
}

.status-delivered,
.status-pick-picked {
    background-color: #79963c !important;
    color: white;
}

.status-other,
.status-inv-pre-invoice {
    background-color: #667fb5 !important;
}

.status-processed {
    background-color: #e1bee7 !important;
}

.action-button-bg {
    background-color: #e1bee7 !important;
    color: #3c4859 !important;
}

.mt-center {
    justify-content: center;
}

// Making child elements visible when hovering on parent
.show-child-on-hover .hover-child {
    visibility: hidden;
}

.show-child-on-hover:hover .hover-child {
    visibility: visible;
}

.popover-title {
    margin-bottom: 12px;
    border-bottom: 1px solid darkgray;
    padding-bottom: 4px;
}

.bordered-popover {
    border: 1px solid gray;
    background: white;
    padding: 16px;
    border-radius: 3px;
}

.di-filter-count {
    padding: 5px 10px;
    background-color: #2cabb8;
    font-size: 0.9em;
    border-radius: 6px;
    color: white;
}

.di-filter-active {
    color: #1890ff !important;
}

.di-search-addon-button {
    margin-top: 2.5px;
}

//
//FixMe: the below section should be in the css file for the map-based planning screen, instead of here
//but currently for some reason the heremaps element on that screen are only recognising css from this file
//

.addToRunButton {
    padding: 5px 10px 5px 10px;
    border: 0;
    background: #1b5299;
    color: #fff;
    border-radius: 6px;
    margin-right: 10px;
}

.addToRunButton:hover {
    cursor: pointer;
}

.removeToRunButton {
    padding: 5px 10px 5px 10px;
    border: 0;
    background: #383838;
    color: #fff;
    border-radius: 6px;
    margin-right: 10px;
}

.removeToRunButton:hover {
    cursor: pointer;
}

.point-marker {
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    width: 35px;
    text-align: center;
    padding: 5px;
    color: #383838;
    height: 35px;
    background: #f6f7f8;
    border-radius: 50%;
    border: 3px solid #3aa36b;
}

.some-selected {
    color: white;
    box-shadow: inset 0 0 0 4px #f6f7f8;
    background: #3aa36b;
}

.all-selected {
    color: white;
    box-shadow: inset 0 0 0 0.5px #f6f7f8;
    background: #3aa36b;
}

.small-font {
    font-size: 10px;
}

//
//end map-based planning section
//

// Ash Edit
.di-table-pane,
.card {
    border-radius: 8px !important;
    overflow: hidden !important;
    box-shadow: 0px 0px 40px 0px #cad5df !important;
}

.card-table {
    border-radius: 8px !important;
    overflow: hidden !important;
    border: 1px solid #efefef;
}

.nav .nav-group > .group-title .hint-text {
    color: #000 !important;
    font-weight: bold !important;
    background: transparent !important;
}

.navbar-scroll-container.fuse-navy-700.ps {
    background: #f9f9f9 !important;
}

.nav .nav-item .nav-link .collapsable-arrow,
.nav .nav-item .nav-link .nav-link-icon,
.nav .nav-item .nav-link > .nav-link-title {
    color: #9293a3;
    font-weight: normal;
}

mat-icon.mat-icon.notranslate.secondary-text.material-icons.mat-icon-no-color {
    color: #9293a3 !important;
}

a.nav-link.ng-star-inserted.active.accent,
.logo-text.secondary-text {
    color: #2cabb8 !important;
    font-weight: bold !important;

    * {
        color: #2cabb8 !important;
        font-weight: bold !important;
    }
}

button.mat-focus-indicator.toggle-sidebar-folded.rm-bg.mat-icon-button.mat-button-base {
    background: transparent;
}

a {
    color: #2cabb8 !important;
    text-decoration: none !important;
    font-weight: bold !important;
}

nz-input-group.ant-input-affix-wrapper {
    position: relative;
    width: 100%;
    padding: 4px 20px 4px 10px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    border-radius: 4px;
    transition: all 0.3s;
    display: inline-flex;

    input {
        background: transparent;
        padding-left: 10px !important;
    }

    .anticon-search {
        color: #2cabb8;
        font-size: 19px;
        opacity: 0.5;
    }
}

.ant-btn {
    background: #2cabb8 !important;
    color: #fff !important;
    font-weight: bold !important;
    border: none !important;
    border-radius: 4px !important;
}

.nav > .nav-group > .group-items > .nav-collapsable.open {
    background: rgba(0, 0, 0, 0.04);
}

.full-height {
    height: 100%;
}

.dhx_cal_tab {
    border: 1px solid #2cabb857 !important;
}

.ant-collapse {
    border: none !important;
    background-color: transparent !important;
}

.ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #d9d9d95c !important;
    font-weight: bold !important;
    font-size: 10px !important;
}

.resource-scheduler-container .available-driver-panel-header {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 11px;
}

input.filter-text.ng-pristine {
    font-size: 12px;
    color: #c6c6cf !important;
    font-style: italic;
}

.dhx_scale_bar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dhx_cal_navline .dhx_cal_date {
    border: 0 !important;
    font-size: 15px !important;
    font-family: Muli, 'Helvetica Neue', Arial, sans-serif !important;
    font-weight: bold !important;
}

.page-title {
    *:not(button *) {
        font-weight: bold;
        color: #2cabb8;
    }
    min-width: 23em;
}

.ant-table.ant-table-small .ant-table-thead > tr > th {
    white-space: nowrap;
    font-size: 12px;
    padding: 4px 10px 4px 10px !important;
}

.ant-tabs-tab-btn {
    font-weight: bold;
}

.ant-tabs-tab {
    color: #9293a3;
}

.ant-tabs-tab-btn:active,
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:active,
.ant-tabs-tab-remove:focus,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus,
.ant-tag-blue,
.ant-tabs-tab:hover,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #2cabb8 !important;
}

.ant-tabs-ink-bar {
    background: #2cabb8 !important;
}

.ant-modal-content {
    border-radius: 8px !important;
}

.ant-table-column-title + di-search-filter,
.ant-table-column-title + span {
    padding-left: 5px;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background: rgb(255 255 255 / 27%) !important;
}

.ant-table.ant-table-small .ant-table-tbody > tr > td {
    font-size: 12px !important;
    font-weight: normal !important;
    padding: 4px !important;
    color: black;
}

.ant-pagination {
    font-size: 12px !important;

    * {
        font-size: 12px !important;
    }
}

.ant-pagination-item-active {
    border-color: #666b72 !important;
}

.dhx_cal_navline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    * {
        position: relative !important;
        top: auto !important;
        bottom: auto !important;
        left: auto !important;
        right: auto !important;
    }
}

.dhx_matrix_scell {
    padding: 20px;
}

// Sticky Table Headers
.ant-table-thead {
    position: sticky !important;
    top: 0px !important;
    width: 100% !important;
    z-index: 9;
}

.reject-button {
    background: #f44336 !important;
}

.icon-button {
    color: #2cabb8;
    cursor: pointer;
}


.toast-top-right {
    top: auto !important;
    bottom: 12px !important;
    right: 12px !important;
}

.ant-checkbox-inner {
    border: 1px solid darkgrey !important;
}